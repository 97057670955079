<template>
<a-layout>
    <a-layout-content>
        <a-row class="info" v-bind:class="[ _mobile  ? '' : 'pc' ]">
            <a-col :span="span" :offset="offSet">
                <div style="display: table;margin: 0 auto;      width: 92%;">
                    <div>
                        <div class="header">공지사항</div>
                        <div>
                            <a-collapse v-model="activeKey">
                                <a-collapse-panel :header="item.title" v-for="item in contents" :key="item.key">
                                    <span v-html="item.data" style="text-align:center;height:calc(100vh - 500px);background-color:white;text-align:left;padding:20px;overflow-y:scroll"></span>
                                </a-collapse-panel>
                            </a-collapse>

                        </div>

                    </div>
                </div>
            </a-col>
        </a-row>

    </a-layout-content>
    <nav-footer></nav-footer>
</a-layout>
</template>

<script>
Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

import {
  getLicense,
  replaceAll,
  getCurrentTime,
  isMobile,
  showError,
  showSuccess
} from "../components/fnc.js";
import firebase from "firebase";
import NavFooter from "../../layouts/nav/NavFooter";
export default {
  components: {
    NavFooter
  },
  data() {
    return {
      activeKey: ["1"],
      span: 8,
      offSet: 8,
      contents: []
    };
  },
  mounted() {
    this.onLoadNotification();
    fbq("track", "PageView");
  },
  computed: {
    _mobile: function() {
      var _mobile = this.$vssWidth < 1024 ? true : isMobile();
      this.span = _mobile ? 24 : 12;
      this.offSet = _mobile ? 0 : 6;
      return _mobile;
    },
    getName() {
      return this.$store.state.AppActiveUser.displayName;
    },
    getUid() {
      return this.$store.state.AppActiveUser.uid;
    },
    getEmail() {
      return this.$store.state.AppActiveUser.email;
    },
    getUserKey() {
      return this.$store.state.AppActiveUser.key;
    }
  },
  methods: {
    onLoadNotification() {
      var idx = 1;
      var self = this;
      self.contents = [];
      var sref = firebase.database().ref("post/");

      sref.once("value", function(sdata) {
        sdata.forEach(function(sdatas) {
          var _t = {
            key: idx.toString(),
            title: sdatas.val().title,
            data: sdatas.val().contents
          };
          self.contents.push(_t);
          idx++;
        });
      });
    }
  }
};
</script>

<style>
.info .ant-collapse-header {
  border-bottom: solid 1px #ffffff !important;
  background-color: white;
}

.info .ant-collapse-content {
  border-top: 1px solid #ffffff !important;
}

.info .anticon {
  color: #aaaaaa !important;
}

.info .header {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.38px;
  color: #000000;
  margin-top: 50px;
  margin-bottom: 16px;
}
</style>
